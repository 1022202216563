<!-- (c) 智汇考题 -->
<template>
    <div class="choice-item">
        <div v-if="previewImage" class="preview-image borderbox" @click="backDetail()">
            <img
                class="back-detail"
                src="../assets/img/back-s.png"
            />
            <div class="pic-item">
                <div
                class="pic"
                :style="{ 'background-image': 'url(' + thumb + ')' }"
                ></div>
            </div>
        </div>
        <div class="question-box">
            <div class="item">
                <div class="info">
                    <div class="type">{{ num }}.{{ question.type_text }}（{{ question.score }}分）</div>
                </div>
                <div class="question-content">
                    <div class="content-render" @click="PreviewImage($event)" v-latex v-html="question.content">
                    </div>
                </div>
            </div>
            <div class="choice-box">
                <template v-if="isOver">
                    <div
                    class="judge-item"
                    @click="change(1)"
                    :class="{ active: active === 1 }"
                    >
                    <div class="answer-index" v-if="parseInt(question.answer) === 1">
                        <img class="icon" src="../assets/img/icon-right.png" />
                    </div>
                    <div class="answer-index" v-else-if="active === 1">
                        <img class="icon" src="../assets/img/icon-unright.png" />
                    </div>
                    <div class="index" v-else></div>
                    正确
                    </div>
                    <div
                    class="judge-item"
                    @click="change(0)"
                    :class="{ active: active === 0 }"
                    >
                    <div class="answer-index" v-if="parseInt(question.answer) === 0">
                        <img class="icon" src="../assets/img/icon-right.png" />
                    </div>
                    <div class="answer-index" v-else-if="active === 0">
                        <img class="icon" src="../assets/img/icon-unright.png" />
                    </div>
                    <div class="index" v-else></div>
                    错误
                    </div>
                </template>
                <template v-else>
                    <div
                    class="judge-item"
                    @click="change(1)"
                    :class="{ active: active === 1 }"
                    >
                    <div class="index"><strong></strong></div>
                    正确
                    </div>
                    <div
                    class="judge-item"
                    @click="change(0)"
                    :class="{ active: active === 0 }"
                    >
                    <div class="index"><strong></strong></div>
                    错误
                    </div>
                </template>
            </div>
            <div class="line" v-if="isOver"></div>
            <template v-if="isOver">
                <div class="analysis-box">
                    <div class="status">
                        <div class="answer">
                            <i></i>答案：{{parseInt(question.answer) === 1 ? "正确" : "错误"}}
                        </div>
                        <div class="my-answer" v-if="!wrongBook && isCorrect !== 1">
                            <i></i>我的答案：
                            <span v-if="parseInt(active) !== -1">{{
                                parseInt(active) === 1 ? "正确" : "错误"
                            }}</span>
                            <span v-else>--</span>
                        </div>
                        <div class="score" v-if="!wrongBook"><i></i>得分：{{ score }}</div>
                        <div class="level" v-if="question.level">
                            <span></span>难度：
                            <van-rate v-model="question.level" :size="20" color="#ffd21e" void-icon="star" void-color="#eee"/>
                        </div>
                        <div class="remark" v-if="question.remark">
                            <strong class="comment-w">解析：</strong>
                            <div class="content-render" @click="PreviewImage($event)" v-latex v-html="question.remark">
                            </div>  
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Rate } from 'vant-green';
Vue.use(Rate);
export default {
    props: [
        "question",
        "reply",
        "isCorrect",
        "isOver",
        "score",
        "wrongBook",
        "num",
    ],
    data() {
        return {
            active: null,
            previewImage: false,
            thumb: null,
            remarkStatus: false,
        };
    },
    mounted() {
        this.active = this.reply;
        if (this.wrongBook) {
            this.remarkStatus = true;
        }
    },
    watch: {
        reply() {
            this.active = this.reply;
        },
    },
    methods: {
        change(index) {
            if (this.isOver) {
                return;
            }
            this.active = index;
            this.$emit("update", this.question.id, this.active);
        },
        backDetail() {
            this.previewImage = false;
        },
        PreviewImage($event) {
            if ($event.target.src) {
                this.thumb = $event.target.src;
                this.previewImage = true;
            }
        },
        newPreviewImage(src) {
            this.thumb = src;
            this.previewImage = true;
        },
    }
}
</script>
<style lang="less" scoped>
    .question-box {
        width: 100%;
        float: left;
        position: relative;
        padding-bottom: 55px;
        height: auto;
        .info {
            width: 100%;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 15px 0 15px;
            background-color: #fff;
            .type {
                height: 18px;
                font-size: 18px;
                font-weight: 500;
                color: #333;
                line-height: 18px;
            }
        }
        .question-content {
            width: 100%;
            float: left;
            height: auto;
            font-size: 16px;
            font-weight: 400;
            color: #333;
            line-height: 30px;
            padding: 20px 15px;
            background-color: #fff;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            border-bottom: 1px solid #f1f2f6;
            .content-render {
                width: 100%;
                float: left;
                height: auto;
            }
        } 
    }
    .choice-box {
        width: 100%;
        height: auto;
        float: left;
        box-sizing: border-box;
        padding: 20px 15px 0px 15px;
        background-color: #fff;
        .judge-item {
            width: 100%;
            height: 20px;
            float: left;
            box-sizing: border-box;
            display: flex;
            margin-bottom: 30px;
            border-radius: 3px;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            &.active .index strong {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #3ca7fa;
            }

            .answer-index {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                margin-right: 10px;
                .icon {
                width: 20px;
                height: 20px;
                }
            }

            .index {
                width: 22px;
                height: 22px;
                border: 1px solid #ccc;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 500;
                color: #333;
                margin-right: 20px;
            }
        }
  }
  .line {
    width: 100%;
    float: left;
    height: 10px;
    background: #f1f2f6;
  }
  .preview-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 400;
    padding: 15px;
    background-color: #000000;
    display: flex;
    align-items: center;
    .back-detail {
      position: absolute;
      top: 15px;
      left: 15px;
      width: 19px;
      height: 19px;
      cursor: pointer;
    }
    .pic-item {
      width: 100%;
      height: 100%;
      .pic {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
    }
  }
</style>
