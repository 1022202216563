import { render, staticRenderFns } from "./question-choice.vue?vue&type=template&id=5c294457&scoped=true&"
import script from "./question-choice.vue?vue&type=script&lang=js&"
export * from "./question-choice.vue?vue&type=script&lang=js&"
import style0 from "./question-choice.vue?vue&type=style&index=0&id=5c294457&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c294457",
  null
  
)

export default component.exports