<!-- (c) 智汇考题 -->
<template>
  <div class="choice-item">
    <div v-if="previewImage" class="preview-image borderbox" @click="backDetail()">
      <img
        class="back-detail"
        src="../assets/img/back-s.png"
      />
      <div class="pic-item">
        <div
          class="pic"
          :style="{ 'background-image': 'url(' + image.thumb + ')' }"
        ></div>
      </div>
    </div>
    <div class="question-box">
        <div class="info">
        <span class="tit"
            >{{ num }}.{{ question.type_text }}（{{ question.score }}分）</span
        >
        </div>
        <div class="question-content">
          <div class="content-render" @click="PreviewImage($event)" v-latex v-html="question.content">
          </div>
        </div>
        <div class="choice-box">
        <div class="input-title">我的作答</div>
        <textarea
            :disabled="isOver"
            v-model="val"
            placeholder="请输入你的答案"
            @blur="change"
            class="input"
            maxlength="-1"
        ></textarea>
        </div>
        <div class="line" v-if="isOver"></div>
        <template v-if="isOver">
            <div class="analysis-box">
              <div class="status">
                 <div class="score" v-if="!wrongBook">
                      <i></i>得分：{{ score }}
                  </div>
                <div class="level" v-if="question.level">
                    <span></span>难度：
                    <van-rate v-model="question.level" :size="20" color="#ffd21e" void-icon="star" void-color="#eee"/>
                </div>
                <div class="remark" v-if="question.remark">
                    <strong class="comment-w">解析：</strong>
                    <div class="content-render" @click="PreviewImage($event)" v-latex v-html="question.remark">
                    </div>
                </div>
              </div>
            </div>
        </template>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Rate } from 'vant-green';
Vue.use(Rate);
export default {
  props: [
    "question",
    "reply",
    "isCorrect",
    "isOver",
    "thumbs",
    "score",
    "showImage",
    "wrongBook",
    "num",
  ],
  data() {
    return {
      qid: null,
      val: null,
      localThumbs: [],
      previewImage: false,
      image: {
        thumb: null,
        index: null,
      },
      prew: false,
      remarkStatus: true,
      showDelIcon: true,
    };
  },
  mounted() {
    if (this.thumbs) {
      if (this.isJson(this.thumbs)) {
        this.localThumbs = JSON.parse(this.thumbs);
      } else {
        this.localThumbs = this.thumbs;
      }
    }
    this.val = this.reply;
  },
  watch: {
    reply() {
      this.val = this.reply;
    },
  },
  methods: {
    isJson(str) {
      if (typeof str == "string") {
        try {
          let obj = JSON.parse(str);
          if (typeof obj == "object" && obj) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      }
    },
    change(e) {
      if (this.isOver) {
        return;
      }
      this.emitCall();
    },
    uploadImage(e) {
      if (this.isOver) {
        return;
      }
      if (this.localThumbs.length >= 9) {
        this.$message.error("最多上传9张图片");
        return;
      }
      let files = e.target.files;
      if (!files[0].type.match(/.jpg|.png|.jpeg/i)) {
        this.$message.error("图片格式错误,请上传png/jpg/jpeg格式的图片");
        return;
      }
      let formData = new FormData();
      formData.append("file", files[0]);
      this.$api.Exam.UploadPaperImages(formData)
        .then((res) => {
          let url = res.data.url;
          this.localThumbs.push(url);
          this.emitCall();
        })
        .catch((e) => {
          this.$message.error("上传图片出现错误");
        });
    },
    backDetail() {
      this.previewImage = false;
    },
    deleteImage() {
      if (this.isOver) {
        return;
      }
      this.localThumbs.splice(this.image.index, 1);
      this.previewImage = false;
      this.emitCall();
    },
    PreviewImage(val, index) {
      this.showDelIcon = true;
      this.previewImage = true;
      this.prew = false;
      this.image.thumb = val;
      this.image.index = index;
    },
    headerPreviewImage(src) {
      this.showDelIcon = false;
      this.image.thumb = src;
      this.previewImage = true;
    },
    newPreviewImage(src) {
      this.showDelIcon = false;
      this.image.thumb = src;
      this.previewImage = true;
    },
    emitCall() {
      this.$emit("update", this.question.id, this.val, this.localThumbs);
    },
  },
};
</script>
<style lang="less" scoped>
        .question-box {
            width: 100%;
            float: left;
            position: relative;
            padding-bottom: 55px;
            height: auto;
        .info {
            width: 100%;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 15px 0 15px;
            background-color: #fff;
            .type {
                height: 18px;
                font-size: 18px;
                font-weight: 500;
                color: #333;
                line-height: 18px;
            }
        }
        .question-content {
            width: 100%;
            float: left;
            height: auto;
            font-size: 16px;
            font-weight: 400;
            color: #333;
            line-height: 30px;
            padding: 20px 15px;
            background-color: #fff;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            border-bottom: 1px solid #f1f2f6;
            .content-render {
                width: 100%;
                float: left;
                height: auto;
            }
        } 
    }
    .choice-box {
        width: 100%;
        height: auto;
        float: left;
        box-sizing: border-box;
        padding: 20px 15px 20px 15px;
        background-color: #fff;
        .choice-tap-item {
            width: 100%;
            height: auto;
            float: left;
            box-sizing: border-box;
            display: flex;
            margin-bottom: 30px;
            border-radius: 3px;
            border: none;
            .icon {
                width: 24px;
                height: 24px;
            }
            &.active .index {
                background: #3ca7fa;
                border: 1px solid #3ca7fa;
                color: #fff;
            }
            .index {
                width: 22px;
                height: 22px;
                border: 1px solid #ccc;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 500;
                color: #333;
            }
            .content {
                flex: 1;
                color: #000;
                padding-left: 20px;
                line-height: 24px;
                font-size: 16px;
                font-weight: 400;
                color: #333;
                flex-wrap: wrap;
            }
        }
        .input-title {
            width: 100%;
            height: 15px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .input {
            height: 140px;
            color: #666;
            outline: none;
            resize: none;
            background-color: #fff;
            width: 100%;
            border-radius: 4px;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            padding: 10px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }
    }
    .line {
        width: 100%;
        float: left;
        height: 10px;
        background: #f1f2f6;
    }
    .preview-image {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 400;
        padding: 15px;
        background-color: #000000;
        display: flex;
        align-items: center;
        .back-detail {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 19px;
        height: 19px;
        cursor: pointer;
        }
        .pic-item {
        width: 100%;
        height: 100%;
        .pic {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }
        }
    }
    .level {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      float: left;
      height: auto;
      margin-top: 20px;
      span {
        width: 4px;
        height: 18px;
        background: #ffd21e;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
</style>
